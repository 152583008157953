<template>
	<div class="table-box">
		<div class="table-container">
			<el-row :gutter="24">
				<el-col :span="24">
					<el-row class="floatLeft">
						<el-input v-model="searchValue" style="width:200px;" placeholder="请输入角色名称"></el-input>
						<el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
					</el-row>
					<el-row class="float">
						<el-button type="primary" icon="el-icon-plus" @click="addRole()" v-if="action.addrole">添加</el-button>
						<!-- <el-button type="primary" icon="el-icon-plus" >导出</el-button> -->
					</el-row>
					<el-table id="out-table" ref="singleTable" stripe :data="roleList" highlight-current-row style="width:100%;"
					 border>
						<el-table-column align="left" label="角色名称">
							<template slot-scope="scope">{{ scope.row.roleName }}</template>
						</el-table-column>
						<el-table-column align="left" label="创建时间">
							<template slot-scope="scope">{{ scope.row.creationTimeStr }}</template>
						</el-table-column>
						<el-table-column label="操作" v-if="action.updaterole||action.deleterole">
							<template slot-scope="scope">
								<i class="el-icon-edit" @click="updateRole(scope.row)" v-if="action.updaterole"></i>
								<i class="el-icon-delete" @click="deletRole(scope.$index,roleList,scope.row)" v-if="action.deleterole"></i>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<el-dialog :title="addOrEdit=='add'?'添加角色':'编辑角色'" :visible.sync="addRoleDialog" width="30%" :close-on-click-modal="false">
				<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" label-position="left" style="text-align: left;">
					<el-form-item label="角色名" prop="roleName">
						<el-input v-model="ruleForm.roleName" placeholder="角色名称" />
					</el-form-item>
					<el-form-item label="权限">
						<el-tree ref="permissionTree" v-if="isShowTree" :data="allPer" @check="handleRoleChange" show-checkbox
						 node-key="id" :props="{children:'children',label: 'permissionName' }" :default-checked-keys="defaultCheckedKeys"
						 check-strictly>
						</el-tree>
					</el-form-item>
				</el-form>
				<div style="text-align:right;">
					<el-button type="danger" @click="addRoleDialog=false">取消</el-button>
					<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>
<script>
import {
  mapState
} from 'vuex'
export default {
  name: 'User',
  data () {
    return {
      roleList: [],
      roleListTemp: [],
      searchValue: '',
      addOrEdit: 'add',
      addRoleDialog: false,
      ruleForm: {
        roleName: ''
      },
      checkPer: [],
      rules: {
        roleName: [{
          required: true,
          message: '角色名不能为空',
          trigger: 'blur'
        }]
      },
      allPer: [{
        permissionName: '全部权限',
        id: -1,
        children: []
      }],
      defaultCheckedKeys: [],
      isShowTree: false
    }
  },
  created () {
    this.getUsers()
  },
  computed: {
    ...mapState('action', {
      action: 'action'
    })
  },
  methods: {
    getUsers () {
      this.$axios.get('/api/Role/GetAllRole').then(res => {
        this.roleList = res
        this.roleListTemp = res
      })
      this.$axios.get('/api/Permission/GetPermission').then(res => {
        this.allPer[0].children = res
      })
    },
    submitForm (ruleForm) {
      var _this = this
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          var roleAdd = {}
          roleAdd.RoleName = _this.ruleForm.roleName
          roleAdd.rolePermissionIds = []
          if (_this.addOrEdit == 'add') {
            _this.checkPer.forEach(element => {
              if (element.id > -1) {
                roleAdd.rolePermissionIds.push(element.id)
              }
            })
            _this.$axios.post('/api/Role/AddRole', roleAdd).then(res => {
              var role = {}
              role.roleName = _this.ruleForm.roleName
              role.creationTimeStr = _this.getFullTime()
              role.roleId = res.id
              role.rolePermissionDtos = []
              _this.checkPer.forEach(element => {
                if (element.id > -1) {
                  var per = {}
                  per.permissionName = element.permissionName
                  per.permissionId = element.id
                  per.id = element.id
                  role.rolePermissionDtos.push(per)
                }
              })
              _this.roleListTemp.push(role)
              _this.$message({
                type: 'success',
                message: '添加角色成功'
              })
              _this.checkPer = []
              _this.isShowTree = false
              _this.defaultCheckedKeys = []
              setTimeout(() => {
                _this.isShowTree = true
              }, 100)
              _this.addRoleDialog = false
              _this.ruleForm = {
                roleName: ''
              }
            })
          } else {
            // console.log(_this.ruleForm)
            roleAdd.Id = _this.ruleForm.roleId
            roleAdd.RoleName = _this.ruleForm.roleName
            roleAdd.creationTimeStr = _this.ruleForm.creationTimeStr
            roleAdd.roleDeletePermissionIds = []
            roleAdd.roleAddPermissionIds = []
            var oldPer = _this.ruleForm.rolePermissionDtos
            var newPer = _this.checkPer
            // console.log(oldPer, newPer)
            oldPer.forEach(element => {
              // 在新选择的所有权限中 如果没有找到原来的，说明是被删除的
              var index = newPer.findIndex(n => n.id == element.permissionId)
              if (index == -1) {
                roleAdd.roleDeletePermissionIds.push(element.permissionId)
              }
            })
            newPer.forEach(element => {
              // 在原有的所有权限中 如果没有找到的项，说明是新增的
              var index = oldPer.findIndex(n => n.permissionId == element.id)
              if (index == -1 && element.id != -1) {
                roleAdd.roleAddPermissionIds.push(element.id)
              }
            })
            // console.log(roleAdd)
            _this.$axios.post('/api/Role/UpdateRole', roleAdd).then(res => {
              var index = _this.roleList.findIndex(n => n.roleId == roleAdd.Id)
              var indexT = _this.roleList.findIndex(n => n.roleId == roleAdd.Id)
              roleAdd.rolePermissionDtos = []
              newPer.forEach(element => {
                var p = {}
                p.permissionId = element.id
                p.id = element.id
                p.permissionName = element.permissionName
                roleAdd.rolePermissionDtos.push(p)
              })
              var rolePush = {}
              rolePush.roleName = roleAdd.RoleName
              rolePush.Id = roleAdd.Id
              rolePush.creationTimeStr = roleAdd.creationTimeStr
              rolePush.roleId = roleAdd.Id
              rolePush.rolePermissionDtos = roleAdd.rolePermissionDtos
              _this.roleListTemp.splice(indexT, 1, rolePush)
              _this.ruleForm = {
                roleName: ''
              }
              // console.log()
              _this.$message({
                type: 'success',
                message: '修改角色成功'
              })
              _this.checkPer = []
              _this.isShowTree = false
              _this.defaultCheckedKeys = []
              setTimeout(() => {
                _this.isShowTree = true
              }, 100)
              _this.addRoleDialog = false
            })
          }
        }
      })
    },
    search () {
      var str = this.searchValue
      if (str == '') {
        this.roleList = this.roleListTemp
        return
      }
      this.roleList = this.roleListTemp.filter(n => {
        return n.roleName.indexOf(str) > -1
      })
    },
    addRole () {
      this.addOrEdit = 'add'
      this.ruleForm = {
        roleName: ''
      }
      this.defaultCheckedKeys = []
      this.checkPer = []
      this.isShowTree = false
      setTimeout(() => {
        this.isShowTree = true
      }, 100)
      this.addRoleDialog = true
    },
    updateRole (role) {
      this.addOrEdit = 'edit'
      this.isShowTree = false
      this.defaultCheckedKeys = []
      this.checkPer = role.rolePermissionDtos
      this.checkPer.forEach(element => {
        element.id = element.permissionId
      })
      var key = []
      this.ruleForm = this.$utils.deepClone(role)
      role.rolePermissionDtos.forEach(e => {
        key.push(e.permissionId)
      })
      // console.log(key)
      this.defaultCheckedKeys = key
      setTimeout(() => {
        this.isShowTree = true
      }, 100)
      this.addRoleDialog = true
      // console.log(role)
    },
    handleRoleChange (one) {
      // 目前所有被选中的id
      var keys = this.$refs.permissionTree.getCheckedKeys()
      // console.log(keys)
      var ischeck = false
      if (keys.indexOf(one.id) == -1) {
        ischeck = false
      } else {
        ischeck = true
      }
      var all = []
      // 生成一个没有层级结构的数组，用于后续查找
      function newAll (arr) {
        for (var item of arr) {
          all.push(item)
          if (item.children.length != 0) {
            newAll(item.children)
          }
        }
      }
      newAll(this.allPer[0].children)

      var key = []
      // 全选的方法
      function findChecked (obj) {
        // if(one)
        key.push(obj.id)
        if (obj.children.length != 0) {
          for (var item of obj.children) {
            // console.log(item)
            findChecked(item)
          }
        }
      }
      // 随机选中某一个的方法
      function checkOne (obj) {
        key.push(obj.id)
        if (obj.parentID) {
          var newobj = all.filter(item => {
            return item.id == obj.parentID
          })
          checkOne(newobj[0])
        }
      }
      function cancel (obj) {
        let length = obj.children.length
        if (length != 0) {
          for (let item of obj.children) {
            let index = keys.findIndex(item2 => {
              return item2 == item.id
            })
            if (index != -1) {
              keys.splice(index, 1)
            }
            cancel(item)
          }
        }
      }
      if (ischeck) {
        // console.log('选中')
        // 是选中时
        // 是全选的时候
        if (one.id == -1) {
          findChecked(one)
          keys = key
        } else {
          // 如果不是全选时
          // 如果存在父元素，要把父元素选上、全选除外,还要把父元素的父元素选上
          if (one.parentID) {
							 checkOne(one)
							 keys.push(...key)
          }
          // 如果没有父元素，就把自己选上
        }
      } else {
        // 取消时需要判定是否有子元素，如果有，子元素也需要被取消
        // 如果是全部权限那个，就直接置为空
        // console.log('取消')
        if (one.id == -1) {
          keys = []
        } else {
          cancel(one)
        }
      }
      this.$refs.permissionTree.setCheckedKeys(keys)
      keys = keys.filter(item => {
        return item != -1
      })
      // console.log(this.allPer[0].children)
      this.checkPer = []
      for (var i = 0; i < this.allPer[0].children.length; i++) {
        this.selectPer(this.allPer[0].children[i], keys)
      }
      // console.log(this.checkPer)
    },
    selectPer (per, keys) {
      if (keys.findIndex(n => n == per.id) > -1) {
        this.checkPer.push(per)
      }
      if (per.children && per.children.length > 0) {
        for (var i = 0; i < per.children.length; i++) {
					    this.selectPer(per.children[i], keys)
				    }
      }
    },

    deletRole (index, rows, role) {
      this.$confirm('此操作将删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/Role/DeleteRole?roleId=' + role.roleId).then(res => {
          rows.splice(index, 1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    getFullTime () {
      let date = new Date() // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + ''
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours())
      let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes())
      let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
      // console.log()
      return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
    }
  }
}
</script>

<style scoped>
	.float {
		float: right;
		margin-bottom: 20px;
	}

	.float>button {
		margin-left: 10px;
		background: #0094CE;
		border: none;
	}

	.floatLeft {
		float: left;
		margin-bottom: 20px;
	}

	.floatLeft>button {
		margin-left: 10px;
		background: #475065;
		border: none;
	}

	.table-box {
		padding: 15px 25px;
		height: calc(100vh - 100px);
		box-sizing: border-box;
		background-color: #e7e7e7;
	}

	.table-container {
		display: flex;
		height: calc(100vh - 170px);
		flex-wrap: wrap;
		overflow-y: auto;
		align-content: flex-start;
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		background-color: #FFFFFF;
		padding: 20px;
	}
</style>
